export const emailFieldConfig = {
  fields: [
    {
      type: 'email',
      compProps: {
        id: 'email',
        label: 'Email Address',
        required: true,
        placeholder: 'Enter email address',
        errorText: 'Email is required',
      },
    },
  ],
  layout: [['email']],
};
