import { vappContainer } from '@/application';
import { logger as log } from '@common/logger';
import { PolicyDetailPageService } from '@pages/PolicyDetailPage/services';

const MODULE_NAME = 'PoliciesDetailPage.machines.states.getBlockchainProofPage';

const getSrv = () => {
  return vappContainer.get<PolicyDetailPageService>(
    PolicyDetailPageService.displayName
  );
};

export const getBlockchainProof = async (context: any, event: any) => {
  const currentState = 'GET_BLOCK_CHAIN_PROOF';
  const method = 'getBlockchainProof';

  return new Promise(async (resolve, reject) => {
    try {
      log.log(MODULE_NAME, 'context: ', context, 'event: ', event);
      const srv = getSrv();
      const content = await srv.agent.getBlockchainProofProps(event?.payload);

      if (content) {
        return resolve({
          key: 'GET_BLOCK_CHAIN_PROOF_SUCCESS',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Page content not found';
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: 'GET_BLOCK_CHAIN_PROOF_ERROR',
          value: msg,
        });
      }
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      return reject({
        key: 'GET_BLOCK_CHAIN_PROOF_ERROR',
        value: err?.message,
      });
    }
  });
};
