export const bodyTitleConfig = {
  selectCountry: {
    title: 'Date of Birth',
    subTitle: 'Select Date of birth where',
    alertText: 'All fields marked with an asterisk (*) are mandatory',
  },
  policyOptions: {
    title: 'Bharat Saral Jeevan Bima',
    subTitle: 'Select your desired coverage limit for hurricane protection',
  },
  emailKyc: {
    title: 'Verify Email - Know Your Customer (KYC)',
    subTitle:
      'To complete your insurance purchase we need to verify your email',
    alertText: 'All fields marked with an asterisk (*) are mandatory',
  },
  kycForm: {
    title: 'Know Your Customer (KYC) Details',
    subTitle:
      'To complete your insurance purchase we need little more information',
    alertText: 'All fields marked with an asterisk (*) are mandatory',
  },
  termsOfUse: {
    title: 'You are almost there!',
    subTitle: 'To complete a policy we need a little bit information about you',
    alertText: 'All fields marked with an asterisk (*) are mandatory',
  },
  preview: {
    title: 'Check your selection and confirm!',
    subTitle: 'To complete your policy purchase, confirm your selection.',
  },
  intendedPurpose: {
    title: 'Use of Proceeds',
    subTitle:
      'Please provide us with an indication as to how you think you would to use the proceeds from any payout that you would receive under this policy.  Select as many options as you think might be relevant for you',
    alertText: 'All fields marked with an asterisk (*) are mandatory',
  },
  paymentMethod: {
    title: 'Select Payment Method',
    subTitle: 'Select your convenient payment method',
  },
};
