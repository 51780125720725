import styled from 'styled-components';
export const Card = styled.div`
  margin: 2% 2% 2% 0;
`;
export const Heading = styled.h3`
  font-size: 16px;
  padding-top: 10px;
  text-align: left;
  font-weight: 700;
  color: ${(props) => props?.color};
`;
export const SubHead = styled.p`
  font-size: 14px;
  text-align: left;
`;
export const Footer = styled.h4`
  text-align: left;
  font-size: 16px;
  font-weight: 700;
`;
export const CheckBox = styled.div`
  text-align: right;
`;
export const Show = styled.div`
  text-align: right;
  font-size: 12px;
`;
