export const footerConfig = {
  footerBrandLogo: 'https://i.ibb.co/YQjWf33/bli-white.png',
  footerBrandTitle: '',
  footerBrandLink: 'https://vlinder.io/',
  footerBrandTitleStyle: {
    fontFamily: 'muli',
  },
  footerBrandDescription:
    'Automated frictionless and zero-touch death claim settlement',
  footerUsefulLinkLabel: 'Company',
  footerUsefulLinks: [
    {
      name: 'About us',
      link: 'https://vlinder.io/#about',
    },
    {
      name: 'Contact us',
      link: 'https://vlinder.io/#contact',
    },
  ],
  footerServiceLinkLabel: 'Other Links',
  footerServiceLinks: [
    {
      name: 'Privacy Policy',
      link: '/privacy',
      target: '_blank',
    },
    {
      name: 'Terms and Condition',
      link: '/terms',
      target: '_blank',
    },
  ],
  footerContactLabel: 'Need Help ? ',
  footerContactLinks: [
    {
      name: 'Email:',
      value: 'info@vlinder.io',
    },
    {
      name: 'Phone:',
      value: '+91 80 4155 7384',
    },
  ],
  footerCopyright: {
    brandTitle: 'vlinder',
    brandLogo: 'https://i.ibb.co/5MN62Z1/klefki-100x100.png',
    brandLink: 'https://www.vlinder.io',
    target: '_blank',
    copyrightTitle: 'vlinder',
    startYear: '2018',
  },
  socialLinks: [
    {
      name: 'facebook',
      link: 'https://www.facebook.com/vlinder.labs.56',
    },
    {
      name: 'linkedin',
      link: 'https://www.linkedin.com/company/vlinder-io/?originalSubdomain=in',
    },
    {
      name: 'twitter',
      link: 'https://twitter.com/govlinder?lang=en',
    },
  ],
};
