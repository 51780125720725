export const policyOptionConfig = {
  options: [
    {
      id: 'option-t',
      title: 'Policy Option 1',
      subTitle: 'Policy Option 1 is the most comprehensive coverage',
      premium: {
        formatted: 'INR 3,500',
      },
    },
    {
      id: 'option-2',
      title: 'Policy Option 2',
      subTitle: 'Policy Option 2 is the most comprehensive coverage',
      premium: {
        formatted: 'INR 2,800',
      },
    },
    {
      id: 'option-3',
      title: 'Policy Option 3',
      subTitle: 'Policy Option 3 is the most comprehensive coverage',
      premium: {
        formatted: 'INR 2,400',
      },
    },
    {
      id: 'oprion-4',
      title: 'Policy Option 4',
      subTitle: 'Policy Option 4 is the most comprehensive coverage',
      premium: {
        formatted: 'INR 2,000',
      },
      payouts: [
        {
          percent: 'NA',
          title: ['Tropical Storm', 'Box 1'],
          value: 'NA',
        },
        {
          percent: 'NA',
          title: ['Category 1', 'Box 1'],
          value: 'NA',
        },
        {
          percent: 'NA',
          title: ['Category 2', 'Box 1, 2'],
          value: 'NA',
        },
        {
          percent: 'NA',
          title: ['Category 3', 'Box 1, 2'],
          value: 'NA',
        },
        {
          percent: '50%',
          title: ['Category 4', 'Box 1, 2, 3'],
          value: '$500',
        },
        {
          percent: '100%',
          title: ['Category 5', 'Box 1, 2, 3'],
          value: '$1000',
        },
      ],
    },
    {
      id: 'option-5',
      title: 'Policy Option 5',
      subTitle: 'Policy Option 5 is the most comprehensive coverage',
      premium: {
        formatted: 'INR 1,800',
      },
      payouts: [
        {
          percent: 'NA',
          title: ['Tropical Storm', 'Box 1'],
          value: 'NA',
        },
        {
          percent: 'NA',
          title: ['Category 1', 'Box 1'],
          value: 'NA',
        },
        {
          percent: 'NA',
          title: ['Category 2', 'Box 1, 2'],
          value: 'NA',
        },
        {
          percent: 'NA',
          title: ['Category 3', 'Box 1, 2'],
          value: 'NA',
        },
        {
          percent: 'NA',
          title: ['Category 4', 'Box 1, 2, 3'],
          value: 'NA',
        },
        {
          percent: '100%',
          title: ['Category 5', 'Box 1, 2, 3'],
          value: '$1000',
        },
      ],
    },
  ],
};
