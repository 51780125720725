export const createOrderConfig = {
  currency: 'INR',
  name: 'Bharat Life Saral Jeevan Bima',
  image: 'https://i.ibb.co/N1bKMG1/saral-jeevan-logo.png',
  provider: 'razorpay',
  id: 'order_M4BXqwkKTzSAtO',
  entity: 'order',
  amount: 476999,
  amount_paid: 0,
  amount_due: 476999,
  receipt: null,
  offer_id: null,
  status: 'created',
  attempts: 0,
  notes: {
    order_id: '1fcc33e2-7c34-4097-8ecc-08a76281b824',
    policyId: '59209888',
    app_id: 'vantr-vlinder-mintpad-payment-gateway',
  },
  created_at: 1687264325,
};
