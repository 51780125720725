import styled from 'styled-components';
export const Header = styled.h4`
  padding-top: 2%;
  padding-left: 2%;
  font-size: 16px;
  font-weight: 700;
`;
export const Cancel = styled.a`
  font-size: 30px;
  color: black;
  float: right;
`;

export const ModalHeading = styled.h5`
  font-size: 16px;
`;
