export const apiConfig = {
  gql: {
    url: 'https://poc-api-irdai.klefki.io.klefki.io/scooby-gql/',
  },
  rest: {
    url: 'https://poc-api-irdai.klefki.io.klefki.io/scooby-iam',
  },
  sendOTPEP: '/user/sign-up',
  SignInUserEP: '/user/sign-in',
  accessTokenEP: '/user/access-token/get',
  baseURL:
    'https://pooja-dev-dc.vlinder.io/irdai-scooby-rest/payment-gateway/success',
  scriptURL: 'https://checkout.razorpay.com/v1/checkout.js',
  keyId: 'rzp_test_zMlQ6S29QkhjEK',
};
