export const kycFormConfig = {
  FIELDS: [
    {
      type: 'text',
      compProps: {
        id: 'firstName',
        label: 'First Name',
        required: true,
        placeholder: 'Enter First Name',
        errorText: 'First Name is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'lastName',
        label: 'Last Name',
        required: true,
        placeholder: 'Enter Last Name',
        errorText: 'Last Name is required',
      },
    },
    {
      type: 'radio',
      compProps: {
        id: 'gender',
        label: 'Select Gender',
        required: true,
        errorText: 'Gender is Required',
        direction: 'horizontal',
        options: [
          {
            id: 'male',
            label: 'Male',
          },
          { id: 'female', label: 'Female' },
          { id: 'other', label: 'Others' },
        ],
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'aadharNo',
        label: 'Aadhar Number',
        required: true,
        placeholder: 'Enter Aadhar Number',
        errorText: 'Aadhar Number is required',
      },
    },
    {
      type: 'select',
      compProps: {
        id: 'idProofType',
        label: 'Select Id Proof Type',
        required: true,
        options: [
          {
            id: 'passport',
            label: 'Passport',
          },
          {
            id: 'voterId',
            label: 'Voter Id',
          },
          {
            id: 'aadhar',
            label: 'Aadhar',
          },
          {
            id: 'driverLicense',
            label: "Driver's license",
          },
        ],
        errorText: 'Id Proof is required',
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        id: 'idProof',
        label: 'Select Id Proof',
        supportedFormat: [
          'image/png',
          'application/pdf',
          'image/jpeg',
          'image/jpg',
        ],
        required: true,
        maxFiles: 1,
        errorText: 'One Id Proof is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'streetAddress',
        label: 'Street Address',
        required: true,
        placeholder: 'Enter residential street address',
        errorText: 'Street Address is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'city',
        label: 'City',
        required: true,
        placeholder: 'Enter city',
        errorText: 'City is required',
      },
    },
    {
      type: 'countrySelect',
      compProps: {
        id: 'country',
        label: 'Country',
        required: true,
        errorText: 'Country is required',
        placeholder: 'Country',
      },
    },
    {
      type: 'phoneNumber',
      compProps: {
        id: 'telephone',
        label: 'Telephone',
        required: true,
        placeholder: 'Enter telephone number',
        errorText: 'Telephone number is required',
      },
    },
    {
      type: 'radio',
      compProps: {
        id: 'addressProofType',
        label: 'Select Address Proof Type',
        required: true,
        errorText: 'Address proof type is required',
        direction: 'horizontal',
        options: [
          {
            id: 'utilityBill',
            label: 'Utility Bill',
          },
          { id: 'bankstatement', label: 'Bank Statement' },
          { id: 'other', label: 'Other' },
        ],
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        id: 'addressProof',
        label: 'Upload Address Proof',
        supportedFormat: [
          'image/png',
          'application/pdf',
          'image/jpeg',
          'image/jpg',
        ],
        required: true,
        maxFiles: 1,
        errorText: 'One Id Proof is required',
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        id: 'healthReport',
        label: 'Upload Health Report',
        supportedFormat: [
          'image/png',
          'application/pdf',
          'image/jpeg',
          'image/jpg',
        ],
        required: true,
        maxFiles: 1,
        errorText: 'Health Report is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'occupation',
        label: 'Occupation',
        required: true,
        placeholder: 'Enter your Occupation',
        errorText: 'Occupation is required',
      },
    },
    {
      type: 'sectionTitle',
      compProps: {
        id: 'beneficiary',
        label: 'Nominee',
        subTitle:
          'The person named as your nominee will only benefit from this policy in the event of your death.',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'beneficiaryFirstName',
        label: 'First Name',
        required: true,
        placeholder: 'Enter First Name',
        errorText: 'First Name is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'beneficiaryLastName',
        label: 'Last Name',
        required: true,
        placeholder: 'Enter Last Name',
        errorText: 'Last Name is required',
      },
    },
    {
      type: 'radio',
      compProps: {
        id: 'beneficiaryGender',
        label: 'Select Gender',
        required: true,
        errorText: 'Gender is Required',
        direction: 'horizontal',
        options: [
          {
            id: 'male',
            label: 'Male',
          },
          { id: 'female', label: 'Female' },
          { id: 'other', label: 'Others' },
        ],
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'beneficiaryAadhar',
        label: 'Aadhar Number',
        required: true,
        placeholder: 'Enter Aadhar Number',
        errorText: 'Aadhar Number is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'beneficiaryStreetAddress',
        label: 'Street Address',
        required: true,
        placeholder: 'Enter residential street address',
        errorText: 'Street Address is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'beneficiaryCity',
        label: 'City',
        required: true,
        placeholder: 'Enter city',
        errorText: 'City is required',
      },
    },
    {
      type: 'countrySelect',
      compProps: {
        id: 'beneficiaryCountry',
        label: 'Country',
        required: true,
        errorText: 'Country is required',
        placeholder: 'Country',
      },
    },
    {
      type: 'email',
      compProps: {
        id: 'beneficiaryEmail',
        label: 'Email Address',
        required: true,
        placeholder: 'Enter email address',
        errorText: 'Email is required',
      },
    },
    {
      type: 'phoneNumber',
      compProps: {
        id: 'beneficiaryTelephone',
        label: 'Telephone',
        required: true,
        placeholder: 'Enter telephone number',
        errorText: 'Telephone number is required',
      },
    },
  ],
  layout: [
    ['firstName', 'lastName'],
    ['gender'],
    ['aadharNo'],
    ['idProofType'],
    ['idProof'],
    ['streetAddress'],
    ['city', 'country'],
    ['email'],
    ['telephone'],
    ['addressProofType'],
    ['addressProof'],
    ['healthReport'],
    ['occupation'],
    ['beneficiary'],
    ['beneficiaryFirstName', 'beneficiaryLastName'],
    ['beneficiaryGender'],
    ['beneficiaryAadhar'],
    ['beneficiaryStreetAddress'],
    ['beneficiaryCity', 'beneficiaryCountry'],
    ['beneficiaryEmail'],
    ['beneficiaryTelephone'],
  ],
  filterIds: [
    'beneficiary',
    'beneficiaryFirstName',
    'beneficiaryLastName',
    'beneficiaryAadhar',
    'beneficiaryGender',
    'beneficiaryStreetAddress',
    'beneficiaryCity',
    'beneficiaryCountry',
    'beneficiaryEmail',
    'beneficiaryTelephone',
    '',
  ],
  validatorConfig: {
    ruleSets: [
      {
        fields: {
          email: [
            {
              type: 'isEmail',
              errorMessage: 'Please enter a valid email address',
            },
          ],
          beneficiaryEmail: [
            {
              type: 'isEmail',
              errorMessage: 'Please enter a valid email address',
            },
          ],
          telephone: [
            {
              type: 'REQUIRED',
              errorMessage: 'Phone Number is required',
            },
            {
              type: 'MINLENGTH',
              minlength: 8,
              errorMessage: 'Minimum length of Phone Number should be 8',
            },
          ],
          beneficiaryTelephone: [
            {
              type: 'REQUIRED',
              errorMessage: 'Phone Number is required',
            },
            {
              type: 'MINLENGTH',
              minlength: 8,
              errorMessage: 'Minimum length of Phone Number should be 8',
            },
          ],
          idProofType: [
            {
              type: 'REQUIRED',
              errorMessage: 'Id proof type is required',
            },
          ],
          addressProofType: [
            {
              type: 'REQUIRED',
              errorMessage: 'Address proof type is required',
            },
          ],
          firstName: [
            {
              type: 'REQUIRED',
              errorMessage: 'FirstName is required',
            },
            {
              type: 'matches',
              pattern: '^[A-Za-z ]+$',
              errorMessage: 'FirstName Should contain only alpha values',
            },
          ],
          lastName: [
            {
              type: 'REQUIRED',
              errorMessage: 'LastName is required',
            },
            {
              type: 'matches',
              pattern: '^[A-Za-z ]+$',
              errorMessage: 'Last Name Should contain only alpha values',
            },
          ],
          occupation: [
            {
              type: 'matches',
              pattern: '^[A-Za-z ]+$',
              errorMessage: 'Occupation Should contain only alpha values',
            },
          ],
          city: [
            {
              type: 'REQUIRED',
              errorMessage: 'City is Required',
            },
            {
              type: 'matches',
              pattern: '^[A-Za-z ]+$',
              errorMessage: 'City Should contain only alpha values',
            },
          ],
          aadharNo: [
            {
              type: 'REQUIRED',
              errorMessage: 'Enter Valid Aadhar Number',
            },
            // {
            //   type: 'matches',
            //   pattern: '^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$',
            //   errorMessage: 'Enter Valid Aadhar Number',
            // },
          ],
          gender: [
            {
              type: 'REQUIRED',
              errorMessage: 'Gender is Required',
            },
          ],
          beneficiaryGender: [
            {
              type: 'REQUIRED',
              errorMessage: 'Gender is Required',
            },
          ],
          beneficiaryAadhar: [
            {
              type: 'REQUIRED',
              errorMessage: 'Enter Valid Aadhar Number',
            },
            // {
            //   type: 'matches',
            //   pattern: '^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$',
            //   errorMessage: 'Enter Valid Aadhar Number',
            // },
          ],
          beneficiaryFirstName: [
            {
              type: 'REQUIRED',
              errorMessage: 'Enter Valid Beneficiary First Name',
            },
            {
              type: 'matches',
              pattern: '^[A-Za-z ]+$',
              errorMessage:
                'Beneficiary First Name Should contain only alpha characters',
            },
          ],
          beneficiaryLastName: [
            {
              type: 'REQUIRED',
              errorMessage: 'Enter Valid Beneficiary Last Name',
            },
            {
              type: 'matches',
              pattern: '^[A-Za-z ]+$',
              errorMessage:
                'Beneficiary Last Name Should contain only alpha values',
            },
          ],
          beneficiaryCity: [
            {
              type: 'REQUIRED',
              errorMessage: 'Enter Valid Beneficiary City',
            },
            {
              type: 'matches',
              pattern: '^[A-Za-z ]+$',
              errorMessage: 'City Should contain only alpha values',
            },
          ],
        },
      },
    ],
  },
};
