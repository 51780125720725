import { vappContainer } from '@/application';
import { BuyInsurancePageService } from '@pages/BuyInsurancePage/services';
import { logger as log } from '@common/logger';
import { StorageModule } from '@vlinder-web/storage-module-react';
import toast from 'react-hot-toast';

const MODULE_NAME = 'profile.machines.states.signInUser';

const getSrv = () => {
  return vappContainer.get<BuyInsurancePageService>(
    BuyInsurancePageService.displayName
  );
};

export const getAccessToken = async (context: any, event: any) => {
  const currentState = 'GET_ACCESS_TOKEN';
  const method = 'getAccessToken';
  const storageSrv = StorageModule.getInstance();

  return new Promise(async (resolve, reject) => {
    try {
      log.log(MODULE_NAME, 'context: ', context, 'event: ', event);

      /*Impliict Admin Role Selection */

      const signInRes = context['signInRes'];
      const _role =
        Array.isArray(signInRes?.userRoles) &&
        signInRes?.userRoles?.find((item: any) => item?.name === 'purchaser');
      const _args = {
        jwtToken: signInRes.signInToken,
        role: _role.uuid,
      };

      /* End Implicit Admin Role Selection */
      const srv = getSrv();
      const content = await srv.agent?.getAccessTokenProps(_args);
      log.log(MODULE_NAME, '"GET_ACCESS_TOKEN":', content);
      if (content && content?.accessToken) {
        if (event?.payload?.rememberMe) {
          storageSrv.set('userProfile', content, 'local');
        } else {
          storageSrv.set('userProfile', content, 'session');
        }
        return resolve({
          key: 'SIGN_IN_USER_SUCCESS',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = content?.error;
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        toast.error(msg);
        return reject({
          key: 'SIGN_IN_USER_FAILED',
          value: content,
        });
      }
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      toast.error(err?.message);
      return reject({
        key: 'SIGN_IN_USER_FAILED',
        value: 'Something went wrong please try again!',
      });
    }
  });
};
