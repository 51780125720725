import { Container, injectable } from 'inversify';
import { EventBusModule } from '@common/event-bus-module';
import { StorageModule } from '@common/storage-module';
import { ProductLandingPageService } from '@pages/LandingPage/services/index';
import { AppNavigatorService } from '@/AppNavigator/services/index';
import { VendingMachinePageService } from '@pages/VendingMachinePage/services';
import { ProductPageService } from '@pages/ProductPage/services';
import { BuyInsurancePageService } from '@pages/BuyInsurancePage/services';
import { SignInPageService } from '@pages/SignInPage/services';
import { MyProfilePageService } from '@pages/MyProfilePage/services';
import { ActualPoliciesPageService } from '@pages/ActualPolicyPage/services';
import { NomineeKYCService } from '@pages/NomineeKYCPage/services';
import { PolicyDetailPageService } from '@pages/PolicyDetailPage/services';
import { BreadcrumbService } from '@services/breadcrumbService';
import { JwtModule } from '@common/jwt-module';

const vappContainer = new Container();

//Modules
vappContainer
  .bind<EventBusModule>(EventBusModule.displayName)
  .to(EventBusModule)
  .inSingletonScope();
vappContainer
  .bind<StorageModule>(StorageModule.displayName)
  .to(StorageModule)
  .inSingletonScope();
vappContainer
  .bind<JwtModule>(JwtModule.displayName)
  .to(JwtModule)
  .inSingletonScope();

//Services
vappContainer
  .bind<ProductLandingPageService>(ProductLandingPageService.displayName)
  .to(ProductLandingPageService)
  .inSingletonScope();
vappContainer
  .bind<AppNavigatorService>(AppNavigatorService.displayName)
  .to(AppNavigatorService)
  .inSingletonScope();
vappContainer
  .bind<VendingMachinePageService>(VendingMachinePageService.displayName)
  .to(VendingMachinePageService)
  .inSingletonScope();
vappContainer
  .bind<ProductPageService>(ProductPageService.displayName)
  .to(ProductPageService)
  .inSingletonScope();
vappContainer
  .bind<BuyInsurancePageService>(BuyInsurancePageService.displayName)
  .to(BuyInsurancePageService)
  .inSingletonScope();
vappContainer
  .bind<SignInPageService>(SignInPageService.displayName)
  .to(SignInPageService)
  .inSingletonScope();
vappContainer
  .bind<MyProfilePageService>(MyProfilePageService.displayName)
  .to(MyProfilePageService)
  .inSingletonScope();
vappContainer
  .bind<ActualPoliciesPageService>(ActualPoliciesPageService.displayName)
  .to(ActualPoliciesPageService)
  .inSingletonScope();
vappContainer
  .bind<PolicyDetailPageService>(PolicyDetailPageService.displayName)
  .to(PolicyDetailPageService)
  .inSingletonScope();
vappContainer
  .bind<NomineeKYCService>(NomineeKYCService.displayName)
  .to(NomineeKYCService)
  .inSingletonScope();
vappContainer
  .bind<BreadcrumbService>(BreadcrumbService.displayName)
  .to(BreadcrumbService)
  .inSingletonScope();
export { vappContainer };
