export const headerConfig = {
  headerLogo: 'https://i.ibb.co/N1bKMG1/saral-jeevan-logo.png',
  headerLink: '/product/bharat-life-saral-jeevan-bima',
  headerTitle: 'Bharat Life Saral Jeevan Bima',
  headerTitleStyle: {
    fontFamily: 'muli',
  },
  navItems: [
    {
      name: 'View Product Info',
      link: '/product/bharat-life-saral-jeevan-bima',
    },
  ],
  buttonItem: {
    name: 'Login',
    link: '/sign-in',
  },
  loginButton: {
    name: 'My Profile',
    link: '/my-profile',
  },
};
