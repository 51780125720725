import { vappContainer } from '@/application';
import { BuyInsurancePageService } from '@pages/BuyInsurancePage/services';
import { StorageModule } from '@vlinder-web/storage-module-react';
const MODULE_NAME = 'profile.machines.states.submitKYC';

const getSrv = () => {
  return vappContainer.get<BuyInsurancePageService>(
    BuyInsurancePageService.displayName
  );
};

export const submitKYC = async (context: any, event: any) => {
  const currentState = 'SUBMIT_KYC';
  const method = 'submitKYC';

  return new Promise(async (resolve, reject) => {
    try {
      const srv = getSrv();
      const _kycData = await srv?.agent?.getKYCSubmitProps(event?.payload);
      if (_kycData?.errors) {
        const msg = _kycData?.errors[0]?.message;
        return reject({
          key: 'SUBMIT_KYC_FAILED',
          value: msg,
        });
      } else if (_kycData?.submitKYCDetails) {
        return resolve({
          key: 'SUBMIT_KYC_SUCCESS',
          value: {
            ...(_kycData || {}),
          },
        });
      } else {
        const msg = 'Page content not found';
        return reject({
          key: 'SUBMIT_KYC_FAILED',
          value: msg,
        });
      }
    } catch (err: any) {
      return reject({
        key: 'SUBMIT_KYC_FAILED',
        value: err?.message,
      });
    }
  });
};
