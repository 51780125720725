export const notificationConfig = {
  updateProfileSuccess: {
    title: 'Woo hoo!',
    icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.illustrations/happy-face-fhp.png',
    description: ['Profile has been updated Successfully'],
    acknowledgementButtonText: 'Close',
  },
  verifyOTP: {
    title: 'Woo hoo!',
    icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.illustrations/happy-face-fhp.png',
    description: ['Phone number has been verified Successfully'],
    acknowledgementButtonText: 'Close',
  },
  disablePurchase: {
    title: 'Policy Purchase Disabled',
    icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.illustrations/fhp_loading_illustration_2.png',
    description: [
      'Policy Purchase is closed for the year 2023, Please comeback for the year 2024',
    ],
    acknowledgementButtonText: 'close',
  },
};
