import { vappContainer } from '@/application';
import { logger as log } from '@common/logger';
import { MyProfilePageService } from '@pages/MyProfilePage/services';

const MODULE_NAME = 'profile.machines.states.verifyOTP';

const getSrv = () => {
  return vappContainer.get<MyProfilePageService>(
    MyProfilePageService.displayName
  );
};

export const verifyOTP = async (context: any, event: any) => {
  const currentState = 'VERIFY_OTP';
  const method = 'verifyOTP';

  return new Promise(async (resolve, reject) => {
    try {
      log.log(MODULE_NAME, 'context: ', context, 'event: ', event);
      const srv = getSrv();
      const content = await srv.agent.getVerifyOTP(event?.payload);
      if (content) {
        return resolve({
          key: 'VERIFY_OTP_SUCCESS',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Page content not found';
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: 'VERIFY_OTP_FAILED',
          value: msg,
        });
      }
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      return reject({
        key: 'VERIFY_OTP_FAILED',
        value: err?.message,
      });
    }
  });
};
