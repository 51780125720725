import styled from 'styled-components';
import { Form } from 'react-bootstrap';

export const FormLabel = styled(Form.Label)`
  font-size: 14px;
  padding-top: 2%;
  color: ${(props) => props?.color};
  font-weight: 600;
`;
export const heading = styled.h3`
  margin-top: 2%;
  font-size: 1.2rem;
`;
export const subheading = styled.h6`
  font-size: 14px;
`;
