export const routes = {
  Home: {
    path: '/',
    id: 'Home',
  },
  SignIn: {
    path: '/sign-in',
    id: 'SignIn',
  },
  Terms: {
    path: '/terms',
    id: 'Terms',
  },
  Privacy: {
    path: '/privacy',
    id: 'Privacy',
  },
  ProductLanding: {
    path: '/product/:id',
    id: 'ProductLanding',
  },
  FAQPage: {
    path: '/product/:id/faq',
    id: 'FAQPage',
  },
  PurchaseFlow: {
    path: '/product/:id/buy',
    id: 'PurchaseFlow',
  },
  MyAccount: {
    path: '/my-profile',
    id: 'MyAccount',
  },
  ProvisionalPolicyDetail: {
    path: '/my-policies/provisional/:id',
    id: 'ProvisionalPolicyDetail',
  },
  ActualPolicyDetail: {
    path: '/my-policies/actual/:id',
    id: 'ActualPolicyDetail',
  },
  ProvisionalPolicies: {
    path: '/my-policies/provisional',
    id: 'ProvisionalPolicies',
  },
  Archived: {
    path: '/my-policies/archived',
    id: 'Archived',
  },
  ArchivedPolicyDetail: {
    path: '/my-policies/archived/:id',
    id: 'ArchivedPolicyDetail',
  },
  PayoutKYC: {
    path: '/my-payout-detail',
    id: 'PayoutKYC',
  },
  ActualPolicies: {
    path: '/my-policies/actual',
    id: 'ActualPolicies',
  },
  WeatherAnalytics: {
    path: '/my-policies/actual/weather-analytics/:id',
    id: 'WeatherAnalytics',
  },
  AnalyticsAPIDetail: {
    path: '/my-policies/actual/weather-analytics/api-detail/:id',
    id: 'AnalyticsAPIDetail',
  },
};
